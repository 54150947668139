


































































































































































































































































































































































































































































import { ColunaRelatorio, FiltroViewForm, Relatorio, ViewRelatorio } from '@/models/views/ViewRelatorio'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { FindRelatorioUseCase, SaveRelatorioUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import Decimal from 'decimal.js'
import Vue from 'vue'
import { Component, Ref, Vue as VueT, Watch } from 'vue-property-decorator'
import vueTabulator from 'vue-tabulator'
import ExportExcel from 'vue-3-export-excel'
import ListaDeLojas from '@/components/usuario/ListaDeLojas.vue'
import { Loja, Usuario } from '@/models'
import DatePickerAniversario from '@/components/ui/DatePickerAniversario.vue'
import ListagemDeRelatorioPorUsuario from '@/components/relatorio/ListagemDeRelatorioPorUsuario.vue'
import DetalhesDeRelatorioDeGrid from '@/components/relatorio/DetalhesDeRelatorioDeGrid.vue'
import { downloadFilesFromS3 } from '@/shareds/s3/files'
import { AgGridVue } from "ag-grid-vue"
import {LicenseManager} from "ag-grid-enterprise"
import { AgGridComponenteDeImagem } from '@/api/aggrid/AgGridComponenteDeImagem'
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale'
import { AgGridState } from '@/models/views/AgGrid'
import { v4 as uuidv4 } from 'uuid'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { DeleteRelatorioUseCase } from '@/usecases/relatorio/DeleteRelatorioUseCase'
import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-066566}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Almode}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Almode}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Almode}_need_to_be_licensed___{Almode}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_September_2025}____[v3]_[01]_MTc1NzE5OTYwMDAwMA==9c5f2e3bec6aeda6768b2994297ce2a8")

Vue.use(ExportExcel)
Vue.use(vueTabulator, { name: 'vue-tabulator' })

@Component({
	components: {
		ListaDeLojas,
		DatePickerAniversario,
		ListagemDeRelatorioPorUsuario,
		DetalhesDeRelatorioDeGrid,
		AgGridVue,
		Confirmacao,
		SeletorDeUsuario,
	},
})
export default class TelaDeRelatorios extends VueT {
	@Ref() form!: HTMLFormElement
	@Ref() table!: HTMLFormElement
	@Ref() dialogoDeListagemDeRelatorioPorUsuario!: ListagemDeRelatorioPorUsuario
	@Ref() agGrid!: AgGridVue
	findUseCase = new FindRelatorioUseCase()
	saveUseCase = new SaveRelatorioUseCase()
	deleteUseCase = new DeleteRelatorioUseCase()

	AG_GRID_LOCALE_BR = AG_GRID_LOCALE_BR

	views: ViewRelatorio[] = []
	obrigatorio = obrigatorio
	relatorioSelecionado: ViewRelatorio | null = null
	tipoDeFiltroSelecionado: string | null = null
	valorFiltro: string | null = null
	tiposDeFiltro = [
		'Igual',
		'Menor',
		'Menor ou Igual',
		'Maior',
		'Maior ou Igual',
		'Diferente',
		'Contém',
		'Não Contém',
	]
	dadosDaTabela: object[] = []
	dadosDaTabelaFiltrados: object[] = this.dadosDaTabela
	listFiltros: FiltroViewForm[] = []
	colunaSelecionada: string | null = null
	options = {
		layout:'fitData',
		data:this.dadosDaTabela,
		height:'311px',
		groupBy: '',
		groupClosedShowCalcs:true,
		movableColumns:true,
		persistence:{
			sort:true,
			filter:true,
		},
		//persistenceID:'examplePersitstance',
		placeholder:'Sem dados para exibir',
		columns: this.colunasFormatadas,
		groupHeader: function(value, count){
			return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>"
		},
		columnCalcs:"both",
		minHeight:600,
		groupStartOpen:true,
	}
	valid: boolean | true = true
	loading: boolean | false = false
	exibirGrid: boolean | false = false
	lojas: Loja[] = []
	mostrarSeletorDeTipoDeRelatorio = false
	formatoDoRelatorioSelecionado: string | null = null
	loadingGeracaoRelatorio = false
	loadingExportandoExcel = false
	salvandoPreset = false
	removendoPreset = false

	relatorioDetalhado: ViewRelatorio | null = null
	mostraDialogoDeListagemDeRelatorios = false
	mostraDialogoDeDetalheDeRelatorio = false
	mostraDialogoDeSalvamentoDePreset = false
	mostraDialogoDeCompartilharVisao = false
	confirmandoCompartilhamentoDaVisao = false
	carregandoPresets = false
	aba = null
	carregandoOpcoesDosFiltros = false
	opcoesPorFiltro: any[] = []
	expandirTudo = true

	gridApi: any = null
	presetSalvo: any = null
	gridVisible = true
	initialState: any = null
	presetSalvoSelecionadoGrid: AgGridState | null = null
	estadosSalvosGrid: AgGridState[] = []
	buscapresetSalvoGrid: any = null
	nomeDoPreset: string | null = null
	usuarioParaCompartilhar: Usuario | null = null
	presetClicado: AgGridState | null = null

	columnTypes = {
		currency: { 
			width: 150,
			valueFormatter: this.currencyFormatter,
		},
		shaded: {
			cellClass: 'shaded-class',
		},
		imagem: {
			width: 500,
		},
	}

	statusBar = {
		statusPanels: [
			{ statusPanel: 'agTotalAndFilteredRowCountComponent' },
			{ statusPanel: 'agFilteredRowCountComponent' },
			{ statusPanel: 'agSelectedRowCountComponent' },
			{ statusPanel: 'agAggregationComponent' },
		],
	}

	sideBar = {
		toolPanels: [
			{
				id: "columns",
				labelDefault: "Editar",
				labelKey: "colunas",
				iconKey: "columns",
				toolPanel: "agColumnsToolPanel",
				minWidth: 225,
				width: 225,
				maxWidth: 225,
			},
		],
		position: "left",
	}

	defaultColDef = {
		flex: 1,
		minWidth: 100,
		enableRowGroup: true,
		enablePivot: true,
		filter: true,
	}

	alturaDaColuna = 50

	autoGroupColumnDef = {
		minWidth: 300,
	}

	grandTotalRow = "bottom"

	painelAberto = 0

	get tema() {
		return this.$vuetify.theme.dark
	}

	get viewsVendas() {
		return this.views.filter(view => view.categoria === 'Vendas')
	}

	get viewsEstoque() {
		return this.views.filter(view => view.categoria === 'Estoque')
	}
	
	get viewsFiscal() {
		return this.views.filter(view => view.categoria === 'Fiscal')
	}

	get viewsFinanceiro() {
		return this.views.filter(view => view.categoria === 'Financeiro')
	}

	selecionarViewDaAba(chave: string) {
		switch(chave) {
			case 'aba-1': 
				return this.viewsVendas
			case 'aba-2': 
				return this.viewsEstoque
			case 'aba-3': 
				return this.viewsFiscal
			case 'aba-4': 
				return this.viewsFinanceiro
			default:
				return []
		}
	}

	get colunas() {
		if (this.dadosDaTabela.length > 0) {
			const filtros = Object.keys(this.dadosDaTabela[0])
			return filtros
		}
		return []
	}

	get exibirExpansorAutomatico() {
		return this.relatorioSelecionado && this.relatorioSelecionado.colunaAgrupadora !== '' && this.relatorioSelecionado.colunaAgrupadora != null
	}

	get colunasFormatadas() {
		const colunas = this.colunas.map(coluna => {
			const colunaConfiguradaEncontrada = this.colunasConfiguradas.find(colunaConfigurada => colunaConfigurada.colunaDaView == coluna)

			if (colunaConfiguradaEncontrada) {

				if (colunaConfiguradaEncontrada.tipoColuna === 'number') {
					return {
						title: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						bottomCalc: colunaConfiguradaEncontrada.totalizador,
						bottomCalcFormatter: colunaConfiguradaEncontrada.formatoExibicao,
						bottomCalcFormatterParams: this.definirFormato(colunaConfiguradaEncontrada),
						sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
						headerFilter: this.minMaxFilterEditor,
						headerFilterFunc: this.minMaxFilterFunction,
						headerFilterLiveFilter: false,
						formatter: colunaConfiguradaEncontrada.formatoExibicao,
						formatterParams: this.definirFormato(colunaConfiguradaEncontrada),
						hozAlign:'right',
						filter: true,
					}
				} else if (colunaConfiguradaEncontrada.tipoColuna === 'boolean') {
					return {
						title: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
						formatter: colunaConfiguradaEncontrada.formatoExibicao,
						hozAlign:'center',
						formatterParams: this.definirFormato(colunaConfiguradaEncontrada),
						filter: true,
					}
				} else if (colunaConfiguradaEncontrada.tipoColuna === 'date') {
					return {
						title: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						hozAlign:'center',
						filter: true,
					}
				} else if (colunaConfiguradaEncontrada.tipoColuna === 'image') {
					return {
						title: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
						formatterParams: {
							height:"80px",
							width:"80px",
						},
						formatter: colunaConfiguradaEncontrada.tipoColuna,
						filter: true,
					}
				}

				return {
					title: colunaConfiguradaEncontrada.nomeColuna,
					field: coluna,
					sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
					headerFilter:"input",
					headerFilterPlaceholder:"Pesquisar",
					headerFilterFunc: this.buscandoPorColuna,
					hozAlign:'left',
				}
			}

			return {
				title: coluna,
				field: coluna,
				sequencia: Number.MAX_VALUE,
				headerFilter:"input",
				headerFilterPlaceholder:"Pesquisar",
			}
		})

		colunas.sort(this.ordenar)

		return colunas.map(coluna => {
			const { sequencia, ...colunaSemSequencia } = coluna
			return colunaSemSequencia
		})
	}

	get colunasFormatadasAgGrid() {
		const colunas = this.colunas.map(coluna => {
			const colunaConfiguradaEncontrada = this.colunasConfiguradas.find(colunaConfigurada => colunaConfigurada.colunaDaView == coluna)

			if (colunaConfiguradaEncontrada) {

				if (colunaConfiguradaEncontrada.tipoColuna === 'number') {
					return {
						headerName: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						aggFunc: colunaConfiguradaEncontrada.totalizador,
						sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
						type: this.definirFormatoDeExibicao(colunaConfiguradaEncontrada.formatoExibicao) ? 
							[ this.definirFormatoDeExibicao(colunaConfiguradaEncontrada.formatoExibicao), 'rightAligned' ]
							:
							'rightAligned',
						rowGroup: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						hide: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						resizable: true,
						filter: 'agNumberColumnFilter',
						enableRowGroup: true,
        				enablePivot: true,
						enableValue: true,
					}
				} else if (colunaConfiguradaEncontrada.tipoColuna === 'boolean') {
					return {
						headerName: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
						type: this.definirFormatoDeExibicao(colunaConfiguradaEncontrada.formatoExibicao),
						rowGroup: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						hide: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						resizable: true,
						filter: 'agSetColumnFilter',
					}
				} else if (colunaConfiguradaEncontrada.tipoColuna === 'date') {
					return {
						headerName: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						type:'rightAligned',
						rowGroup: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						hide: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						resizable: true,
						filter: 'agTextColumnFilter',
					}
				} else if (colunaConfiguradaEncontrada.tipoColuna === 'image') {
					return {
						headerName: colunaConfiguradaEncontrada.nomeColuna,
						field: coluna,
						sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
						rowGroup: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						hide: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
						cellRenderer: AgGridComponenteDeImagem,
						resizable: true,
						type: 'imagem',
						filter: 'agTextColumnFilter',
					}
				}

				return {
					headerName: colunaConfiguradaEncontrada.nomeColuna,
					field: coluna,
					sequencia: colunaConfiguradaEncontrada.sequencia || Number.MAX_VALUE,
					rowGroup: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
					hide: this.relatorioSelecionado?.colunaAgrupadora === colunaConfiguradaEncontrada.colunaDaView ? true : false,
					resizable: true,
					filter: 'agTextColumnFilter',
				}
			}

			return {
				headerName: coluna,
				field: coluna,
				sequencia: Number.MAX_VALUE,
				resizable: true,
				filter: 'agTextColumnFilter',
			}
		})

		colunas.sort(this.ordenar)

		if (this.colunasConfiguradas.filter(c => c.tipoColuna === 'image').length > 0) {
			this.alturaDaColuna = 90
		}

		return colunas.map(coluna => {
			const { sequencia, ...colunaSemSequencia } = coluna
			return colunaSemSequencia
		})
	}

	get colunasConfiguradas(): ColunaRelatorio[] | [] {
		return this.relatorioSelecionado ? this.relatorioSelecionado.colunas : []
	}

	get primeiroRegistroDaTabela() {
		return this.dadosDaTabela[0]
	}

	get nomesDasColunasParaBaixarExcel() {
		if (this.dadosDaTabelaFiltrados.length > 0) {

			const colunasExcelFormatadas = {}
			const quantidadeDeColunas = Object.keys(this.dadosDaTabelaFiltrados[0]).length

			for(let i = 0;i < quantidadeDeColunas; i++) {
				colunasExcelFormatadas[this.colunasFormatadas[i].title] = this.colunasFormatadas[i].field
			}
			return colunasExcelFormatadas
		}

		return []
	}

	currencyFormatter(params) {
		if (params.value == null) {
			return ""
		} else {
			const formatador = new Intl.NumberFormat('pt-BR', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			return "R$ " + formatador.format(parseFloat(Number(params.value).toFixed(2)))
		}
	}

	opcoesPorFiltroFormatado(filtroId) {
		const opcoesDoFiltro = this.opcoesPorFiltro.filter(opcao => opcao.filtroId === filtroId).map(opcao => opcao.opcoes)
		return opcoesDoFiltro && opcoesDoFiltro.length > 0 ? opcoesDoFiltro[0] : null
	}

	cancelToken: CancelTokenSource | null = null

	created() {
		this.carregar()
	}

	minMaxFilterEditor (cell, onRendered, success, cancel) {

		let end: any = null

		const container = document.createElement("span")

		const start = document.createElement("input")
		start.setAttribute("type", "number")
		start.setAttribute("placeholder", "Min")
		start.style.padding = "4px";
		start.style.width = "50%";
		start.style.boxSizing = "border-box";

		start.value = cell.getValue();

		function buildValues(){
			success({
				start:start.value,
				end:end.value,
			});
		}

		function keypress(e){
			if(e.keyCode == 13){
				buildValues();
			}

			if(e.keyCode == 27){
				cancel();
			}
		}

		end = start.cloneNode();
		end.setAttribute("placeholder", "Max");

		start.addEventListener("change", buildValues);
		start.addEventListener("blur", buildValues);
		start.addEventListener("keydown", keypress);

		end.addEventListener("change", buildValues);
		end.addEventListener("blur", buildValues);
		end.addEventListener("keydown", keypress);


		container.appendChild(start);
		container.appendChild(end);

		return container;
	}

	minMaxFilterFunction(headerValue, rowValue) {
		if(rowValue){
			if(headerValue.start != ""){
				if(headerValue.end != ""){
					return rowValue >= headerValue.start && rowValue <= headerValue.end;
				}else{
					return rowValue >= headerValue.start;
				}
			}else{
				if(headerValue.end != ""){
					return rowValue <= headerValue.end;
				}
			}
		}

		return true
	}

	buscandoPorColuna(headerValue, rowValue) {
		if(rowValue){
			return rowValue.toUpperCase().includes(headerValue.toUpperCase())
		}

		return true
	}

	@Watch("listFiltros")
	onChangeListFiltros() {
		if (!this.listFiltros) return

		this.opcoesPorFiltro = []

		this.listFiltros.forEach(async filtro => {
			if (filtro.possuiOpcoes) {
				const opcoes = await this.carregarOpcoesFiltro(filtro)

				this.opcoesPorFiltro.push({
					filtroId: filtro.id,
					opcoes: opcoes,
				})
			}
		})
	}

	definirFormato(colunaFormatada: ColunaRelatorio) {
		if (!colunaFormatada) return null
		
		switch(colunaFormatada.formatoExibicao) {
			case 'progress':
				return {
					min:0,
					max:100,
					color:["red", "orange", "green"],
					legendAlign:"center",
				}
			case 'money': 
				return {
					decimal:",",
					thousand:".",
					symbol:"R$  ",
					negativeSign:true,
					precision:"2",
				}
			default:
				return null
		}
	}

	ordenar(a, b){
		return a.sequencia - b.sequencia;
	}

	async carregar() {
		this.cancelToken = axios.CancelToken.source()
		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		if (UserLoginStore.perfil) {
			this.views = await this.findUseCase.listarViewsPorPerfil(UserLoginStore.perfil.id, axiosConfig)
		}
	}

	selecionarRelatorio(valor: ViewRelatorio) {
		this.painelAberto = 1
		this.relatorioSelecionado = valor

		if (!this.relatorioSelecionado) return

		this.listFiltros = this.relatorioSelecionado.filtros.map(filtro => {
			return {
				id: filtro.id,
				nomeFiltro: filtro.nomeFiltro,
				valor: '',
				tipo: filtro.tipo,
				possuiOpcoes: filtro.possuiOpcoes,
			} as FiltroViewForm
		})
	}

	async carregarOpcoesFiltro(filtroView: FiltroViewForm) {
		try {
			this.carregandoOpcoesDosFiltros = true
			const opcoes = await this.findUseCase.listarOpcoesDeFiltro(filtroView.id)

			return opcoes
		} catch (error: any) {
			AlertModule.setError(error)
			return null
		} finally {
			this.carregandoOpcoesDosFiltros = false
		}
	}

	aplicarFiltro() {
		if (!this.colunaSelecionada) return
		if (!this.tipoDeFiltroSelecionado) return

		const valorDaColuna = this.primeiroRegistroDaTabela[this.colunaSelecionada]
		
		if ((this.tipoDeFiltroSelecionado.includes('Menor') || this.tipoDeFiltroSelecionado.includes('Maior'))
				&& !this.ehValorNumerico(valorDaColuna)) {
			AlertModule.setError('Campo não numérico, não é possível aplicar esse filtro')
			return
		}
		
		this.dadosDaTabelaFiltrados = this.dadosDaTabela.filter(registro => {
			const valorColuna = registro[!this.colunaSelecionada ? 0 : this.colunaSelecionada]

			return this.validarCodicaoFiltro(valorColuna)
		})
	}

	ehValorNumerico(valor: any) {
		return typeof valor == 'number' 
			|| typeof valor == 'bigint'
			|| valor instanceof Number
			|| valor instanceof Decimal
	}

	validarCodicaoFiltro(valor: any) {
		let valorFormatado = valor
		let valorFiltroFormatado = this.valorFiltro

		if (typeof valor == 'string') {
			valorFormatado = valor.toUpperCase()
		}

		if (typeof this.valorFiltro == 'string') {
			valorFiltroFormatado = this.valorFiltro.toUpperCase()
		}

		switch(this.tipoDeFiltroSelecionado) {
			case 'Igual':
				return valorFormatado == valorFiltroFormatado
			case 'Diferente': 
				return valorFormatado != valorFiltroFormatado
			case 'Menor': 
				return this.valorFiltro ? valor < this.valorFiltro : false
			case 'Menor ou Igual': 
				return this.valorFiltro ? valor <= this.valorFiltro : false
			case 'Maior': 
				return this.valorFiltro ? valor > this.valorFiltro : false
			case 'Maior ou Igual': 
				return this.valorFiltro ? valor >= this.valorFiltro : false
			case 'Contém':
				return valorFormatado.toUpperCase().includes(valorFiltroFormatado)
			case 'Não Contém':
				return !valorFormatado.toUpperCase().includes(valorFiltroFormatado)
		}
	}
	
	downloadCSV() {
		this.table.getInstance().download("csv", "data.csv")
	}

	downloadJson() {
		this.table.getInstance().download("json", "data.json")
	}

	downloadHtml() {
		const nomeRelatorio = this.relatorioSelecionado?.nomeRelatorio.toLowerCase().replaceAll(" ", "_") +
                "_" +
                new Date().getTime()
		this.table.getInstance().download("html", nomeRelatorio+".html", {style:true})
	}

	@Watch('relatorioSelecionado')
	onChange() {
		this.exibirGrid = false
	}

	solicitarFormatoDoRelatorio() {
		if (!this.form.validate()) return
		if (!this.relatorioSelecionado) return
		if (this.relatorioSelecionado.filtrarLoja && this.lojas.length === 0) {
			AlertModule.setInfo('Informe pelo menos uma loja para efetuar a consulta.')
			return
		}

		this.mostrarSeletorDeTipoDeRelatorio = true
	}	

	async selecionarTipoRelatorio() {
		try {
			this.loadingGeracaoRelatorio = true
			await this.aplicarParams(true, false)

			AlertModule.setInfo('Seu relatório está sendo processado e deverá ficar pronto em alguns minutos')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.mostrarSeletorDeTipoDeRelatorio = false
			this.loadingGeracaoRelatorio = false
		}
	}

	async gerarExcelEmSegundoPlano() {
		try {
			this.loadingGeracaoRelatorio = true
			this.formatoDoRelatorioSelecionado = 'excel'
			
			await this.aplicarParams(true, false)

			AlertModule.setInfo('Seu relatório está sendo processado e deverá ficar pronto em alguns minutos')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.mostrarSeletorDeTipoDeRelatorio = false
			this.loadingGeracaoRelatorio = false
		}
	}	

	async buscarPresets() {
		try{
			this.carregandoPresets = true
			if(!this.relatorioSelecionado) throw new Error('Selecione um relatório para buscar os presets')

			this.estadosSalvosGrid = await this.findUseCase.buscarPresetsDoUsuario(this.relatorioSelecionado.id)
			this.carregandoPresets = false
		} catch(error){
			this.carregandoPresets = false
			AlertModule.setError(error)
		}
	}

	async aplicarParams(rodarEmSegundoPlano: boolean, gerarExcel: boolean) {
		if (!this.form.validate()) return
		if (!this.relatorioSelecionado) return
		if (this.relatorioSelecionado.filtrarLoja && this.lojas.length === 0) {
			AlertModule.setInfo('Informe pelo menos uma loja para efetuar a consulta.')
			return
		}

		this.cancelToken = axios.CancelToken.source()

		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		const params = this.listFiltros.map(filtro => {
			const objeto = new Object()

			objeto[filtro.nomeFiltro] = filtro.valor

			return objeto
		})

		try {
			const mapeado = params.map(item => ({ [Object.keys(item)[0]]: Object.values(item)[0] }) )
			const parametrosObject = Object.assign({}, ...mapeado)

			let paramsTratados
			if (this.relatorioSelecionado.filtrarLoja) {
				paramsTratados = {
					...parametrosObject,
					lojasId: String(this.lojas.map(loja => loja.id)),
				}
			} else {
				paramsTratados = parametrosObject
			}

			if (rodarEmSegundoPlano) {
				const parametrosExtras = { ...paramsTratados, gerarRelatorioEmSegundoPlano: true, tipoRelatorio: this.formatoDoRelatorioSelecionado }
				await this.findUseCase.efetuarConsultaRelatorio(this.relatorioSelecionado?.id, parametrosExtras, axiosConfig)
				return
			}

			if (gerarExcel) {
				const parametrosExtras = { ...paramsTratados, gerarRelatorioEmSegundoPlano: false, tipoRelatorio: 'excel' }
				const relatorioGeradoList = await this.findUseCase.gerarExcel(this.relatorioSelecionado?.id, parametrosExtras, axiosConfig)

				if (relatorioGeradoList[0]) {
					const relatorio = relatorioGeradoList[0] as Relatorio

					try {
						const nomeDoArquivo = decodeURIComponent(relatorio.urlArquivo.substring(relatorio.urlArquivo.lastIndexOf('/') + 1))
						const caminhoDaPasta = `relatorios/${relatorio.usuarioId}`
						downloadFilesFromS3(caminhoDaPasta, nomeDoArquivo)
						
					} catch(error) {
						AlertModule.setError(error)
					} finally {
						this.loading = false
					}
				}
				return
			}

			this.loading = true

			this.dadosDaTabela = await this.findUseCase.efetuarConsultaRelatorio(this.relatorioSelecionado.id, paramsTratados, axiosConfig)

			this.estadosSalvosGrid = await this.findUseCase.buscarPresetsDoUsuario(this.relatorioSelecionado.id)

			this.dadosDaTabelaFiltrados = this.dadosDaTabela

			this.options = {
				layout:'fitData',
				data:this.dadosDaTabela,
				height:'311px',
				groupBy:this.relatorioSelecionado.colunaAgrupadora || '',
				groupClosedShowCalcs:true,
				movableColumns:true,
				persistence:{
					sort:true,
					filter:true,
				},
				//persistenceID:'examplePersitstance',
				placeholder:'Sem dados para exibir',
				columns: this.colunasFormatadas,
				groupHeader: function(value, count){
					return value + "<span style='margin-left:10px;'>(" + count + " itens)</span>"
				},
				columnCalcs:"both",
				minHeight:600,
				groupStartOpen:this.expandirTudo,
			}

			this.exibirGrid = true
			this.painelAberto = 3
		} catch(error) {
			this.exibirGrid = false
			AlertModule.setError(error)
		} finally {
			this.loading = false
		}
	}

	limparFiltro() {
		this.dadosDaTabelaFiltrados = this.dadosDaTabela
	}

	mostrarDialogoDeListagemDeRelatorios(relatorio) {
		this.relatorioDetalhado = relatorio
		this.mostraDialogoDeListagemDeRelatorios = true
	}

	fecharListagemDeRelatorio() {
		this.relatorioDetalhado = null
		this.mostraDialogoDeListagemDeRelatorios = false
	}

	mostrarDialogoDeDetalheDeRelatorio(relatorio) {
		this.relatorioDetalhado = relatorio
		this.mostraDialogoDeDetalheDeRelatorio = true
	}

	fecharDetalheDeRelatorio() {
		this.relatorioDetalhado = null
		this.mostraDialogoDeDetalheDeRelatorio = false
	}

	fecharSalvamentoDePreset() {
		this.mostraDialogoDeSalvamentoDePreset = false
		this.nomeDoPreset = null
	}

	onFilterTextBoxChanged(valor: string) {
		this.gridApi.setGridOption(
        	"quickFilterText",
        	valor,
		)
    }

	definirFormatoDeExibicao(formatoDeExibicao: string | null) {
		switch(formatoDeExibicao) {
			case 'money': 
				return 'currency'
			default:
				return formatoDeExibicao
		}
	}

	mostrarDialogoParaSalvarPreset() {
		this.mostraDialogoDeSalvamentoDePreset = true
	}

	async salvarPreset() {
		if (!this.gridApi) return
		if (!this.relatorioSelecionado) return
		if (!this.nomeDoPreset || this.nomeDoPreset.trim() === '') {
			AlertModule.setError('Informe um nome para a visão')
			return
		}

		this.presetSalvo = this.gridApi.getState()

		const estadoAtual = {
			id: uuidv4(),
			nome: this.nomeDoPreset,
			presetSalvo: this.gridApi.getState(),
			viewRelatorioId: this.relatorioSelecionado.id,
			usuarioId: UserLoginStore.usuario?.id || '',
		} as AgGridState

		try {
			this.salvandoPreset = true
			await this.saveUseCase.salvarPreset(estadoAtual)

			this.estadosSalvosGrid.push(estadoAtual)
		} catch(error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvandoPreset = false
			this.fecharSalvamentoDePreset()
			this.buscapresetSalvoGrid
			this.buscarPresets()
		}
	}

	restaurarPreset() {
		const state = this.presetSalvoSelecionadoGrid?.presetSalvo
		this.gridVisible = false
		setTimeout(() => {
			this.initialState = state
			this.gridVisible = true
		})
	}

	onGridReady(params) {
		this.gridApi = params.api
	}

	filtrarPresetSalvo (item: any, queryText: any, itemText: any) {
        if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
	}

	async removerPresetSalvo(preset: AgGridState) {
		if (!preset.id) return
		try {
			this.removendoPreset = true

			await this.deleteUseCase.removerPreset(preset.id)
			const indice = this.estadosSalvosGrid.findIndex(presetSalvoGrid => presetSalvoGrid.id === preset.id)

			this.estadosSalvosGrid.splice(indice, 1)
		} catch(error: any) {
			AlertModule.setError(error)
		} finally {
			this.removendoPreset = false
		}
	}

	@Watch('presetSalvoSelecionadoGrid')
	onChangePreset() {
		this.restaurarPreset()
	}

	mostrarDialogoDeCompartilhamentoDePreset(preset: AgGridState) {
		this.usuarioParaCompartilhar = null
		this.mostraDialogoDeCompartilharVisao = true
		this.presetClicado = preset
	}

	async confirmarCompartilhamentoDaVisao() {
		if (!this.presetClicado?.id) return
		if (!this.usuarioParaCompartilhar) return

		try {
			this.confirmandoCompartilhamentoDaVisao = true
			await this.saveUseCase.compartilharPreset(this.presetClicado.id, this.usuarioParaCompartilhar.id, this.presetClicado.nome)
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.mostraDialogoDeCompartilharVisao = false
			this.confirmandoCompartilhamentoDaVisao = false
			this.presetClicado = null
			AlertModule.setSuccess('Compartilhado com sucesso')
		}
	}
}
